// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyCuDXlcC_hGycmyBW5nkqvhC-VgGQ-tuH8",
    authDomain: "ms-macphee-llc.firebaseapp.com",
    projectId: "ms-macphee-llc",
    storageBucket: "ms-macphee-llc.appspot.com",
    messagingSenderId: "869869428119",
    appId: "1:869869428119:web:78f20bdf7fa831a12df8d2",
    measurementId: "G-4HK1VWZRCB"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

export {
    analytics
};