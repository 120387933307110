import { Container } from "@chakra-ui/react"
import ContactInformation from "../../components/ContactInformation"

const Contact = () => {
    return (
        <Container>
            <ContactInformation />
        </Container>
    )
};

export default Contact;