import { Box, Container, HStack, Text, useBreakpointValue, VStack } from "@chakra-ui/react"
import Logo from "../Logo"
import NavLink from "../NavLink"

const navItems = [
    {
        label: 'Home',
        route: '/'
    },
    {
        label: 'About',
        route: '/about'
    },
    {
        label: 'Contact',
        route: '/contact'
    }
]

const Footer = () => {
    const isMobile = useBreakpointValue({ base: true, md: false })
    return (
        <Box
            px='15px'
            w='full'
            bg='#2A4365'
            color='white'
            marginTop='auto'
        >
            <Container>
                <VStack spacing='20px'>
                    <HStack spacing='20px'>
                        {navItems.map(item => (
                            <NavLink
                                key={item.label}
                                fontSize='20px'
                                to={item.route}>{item.label}</NavLink>
                        ))}
                    </HStack>
                    <Logo />
                    <NavLink fontSize='16px' to='/terms-and-conditions'>
                        Terms and Conditions
                    </NavLink>
                    <NavLink fontSize='16px' to='/privacy-policy'>
                        Privacy Policy
                    </NavLink>
                    <Text
                        fontSize='16px'
                        textAlign='center'
                        style={{
                            fontVariant: 'small-caps'
                        }}>
                        Copyright © 2022  M.S. MacPhee LLC. {isMobile && <br />}All rights Reserved.
                    </Text>
                </VStack>
            </Container>
        </Box >
    )
}

export default Footer