import { defineStyle, defineStyleConfig } from "@chakra-ui/react";

const baseStyle = defineStyle({
    maxW: "1000px",
    py: {
        base: "40px",
        md: "70px"
    },
    // px: {
    //     base: "20px"
    // }
})

export default defineStyleConfig({
    baseStyle
})
