import { Box, Button, Container, Divider, Heading, Text, VStack } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

export const NotFound = () => {
    const navigate = useNavigate();

    return (
        <Box>
            <Container>
                <VStack spacing={{ base: "30px", lg: "40px" }}>
                    <Heading textAlign="center" fontSize="36px">Page Not Found</Heading>
                    <Divider w="100px" />
                    <Text textAlign="center">
                        Looks like the page you are looking for does not exist.
                    </Text>
                    <Button onClick={() => navigate("/")} variant="outline" colorScheme="brand">Go Back Home</Button>
                </VStack>
            </Container>
        </Box>)
}