import { Divider, HStack, Text } from "@chakra-ui/react";

interface BulletTextProps {
    showBullet: boolean;
    children: React.ReactNode;
}

const BulletText = (props: BulletTextProps) => {
    const {
        showBullet,
        children
    } = props;

    return (
        <HStack alignItems="start" spacing="25px">
            {showBullet && <Divider
                orientation="vertical"
                h="60px"
                borderWidth="2px" />}
            <Text>{children}</Text>
        </HStack>
    )
};

export default BulletText;