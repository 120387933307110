import { Box, Container, Divider, Heading, Link, SimpleGrid, Text, useBreakpointValue, VStack } from "@chakra-ui/react"
import BulletText from "../../components/BulletText"
import ContactInformation from "../../components/ContactInformation"
import Dropdown from "../../components/Dropdown"

const clientInterestsText = [
    'Discerning Point of View',
    'Lateral Thinking',
    'Answering Questions',
    'Questioning Answers',
]

const representativeClientsText = [
    'Owner-operators',
    'Investors',
    'Social entrepreneurs, non-profit leaders',
    'Philanthropic donors',
    'Fiduciaries, board members',
    'Consultants, attorneys',
    'Family office principals & professionals',
]

const expertiseText = [
    'Entrepreneurship',
    'Network economics and public policy',
    'Social structure of competition and human capital',
    'Strategic positioning',
    'Process innovation, especially in education and in genealogy',
    'Distance learning and tele-health',
    'Restorative justice for university slaveholding',
    'Problems of ethics, authentication, and forensics in art markets and curatorial practice',
    'Catalogues raisonne and artist estate management',
    'Role of resiliency in educational achievement and in career outcomes',
    'Intergenerational mentoring, associations, friendships',
]

const experienceDropdownText = [
    {
        title: 'Entrepreneurship / Operations',
        content: [
            ['TutorRoom.com'],
            ['The Manhattan Toy Company'],
            ['Neuberger Berman']
        ]
    },
    {
        title: 'Investment Research / Financial Institutions Consulting',
        content: [
            ['Reich & Tang'],
            ['Freeman & Co.']
        ]
    },
    {
        title: 'Non-Profit / Academia',
        content: [
            ['(CITI) Columbia Institute for Tele-Information', 'Columbia Business School'],
            ['Man Ray Rayographs Catalogue Raisonne', 'Toronto New York Paris']
        ]
    },
    {
        title: 'Pro Bono / Community',
        content: [
            ['The Georgetown Memory Project', 'Cambridge Massachusetts'],
            ['George F. Baker Scholars Program', 'Georgetown University']
        ]
    },
    {
        title: 'AB, Georgetown University',
        content: []
    },
    {
        title: 'MBA, Columbia Business School',
        content: []
    }
]

const About = () => {
    const isMobile = useBreakpointValue({ base: true, lg: false })

    return (
        <>
            <Box>
                <Container>
                    <VStack spacing={{ base: "30px", lg: "40px" }}>
                        <Heading as="h1" fontSize="36px" textAlign="center">Michael MacPhee</Heading>
                        <Divider w="100px" />
                        <Text>
                            My consulting practice has evolved out of decades of diverse experience in research, entrepreneurship, and volunteering. The key drivers are imagination and service, each anchored by a wide ranging network of trustworthy, expert resources. What began as freelancing during graduate business school has become a mix of more extended engagements, pro bono projects, and a start-up venture in social entrepreneurship.
                        </Text>
                        {isMobile && <Heading as="h2" fontSize="24px" textAlign="center">Discerning Point of View</Heading>}
                        <Text>
                            {!isMobile && <Heading as="span" fontSize="20px">Discerning Point of View. </Heading>}
                            Clients come to me for an original yet informed point of view, delivered with creativity and candor. Building on 25+ years in research, operating, and staff roles with best-in-class firms, and innovative entrepreneurial ventures including Reich & Tang, Freeman & Co., The Manhattan Toy Company, and TutorRoom.com, I've adapted the more traditional, transactional consulting approach into a trusted advisory service. Rather than performing discrete engagements billed by time or deliverables, I offer clients access and availability on an as-needed basis for an annual fee. Substantial commitment to high impact non-profits such as the Columbia Institute for Tele-Information (<Link href="https://www8.gsb.columbia.edu/citi/" color="teal.500" isExternal>CITI</Link>), and the Georgetown Memory Project (<Link href="https://www.georgetownmemoryproject.org/" isExternal color="teal.500">GMP</Link>), keeps me up to speed with tech innovations and social changes, thought leaders and policy experts, in both the private and public sectors.
                        </Text>
                        {isMobile && <Heading as="h2" fontSize="24px" textAlign="center">Lateral Thinking</Heading>}
                        <Text>
                            {!isMobile && <Heading as="span" fontSize="20px">Lateral Thinking. </Heading>}
                            Consultations and engagements run the gamut, both professional and personal. These two are often intertwined for principals and leaders, particularly when core values are at stake or in conflict. Conversations and assignments about established operations range from discussing sensitive or emergent topical concerns to responding to strategic opportunities or everyday problems. Those at the conceptual stage include advising on newer undertakings or investments, identifying metrics of success, and considering potential pivots necessitated by change. Confidential discussion about balancing life goals with ambitions, talents, limitations is a priority preference of some high trust clients. Likewise for discerning practical ways of exercising moral imagination and courage. Discretion governs all client interactions.
                        </Text>
                        {isMobile && <Heading as="h2" fontSize="24px" textAlign="center">Answering Questions<br />Questioning Answers</Heading>}
                        <Text>
                            {!isMobile && <Heading as="span" fontSize="20px">Answering Questions, Questioning Answers. </Heading>}
                            Expertise referrals center on clarifying ultimate goals and bright line boundaries regarding a specific situation; then I either introduce the most suitable resource from my network or go about identifying, vetting, and recommending other options. Every outreach is tailored in strict accordance with individual client goals, circumstances and prior approval.
                        </Text>
                        <Text>
                            With no financial incentive beyond an annual retainer, all conversation, counsel, and referrals are aligned entirely with each client's agenda, without bias or conflict of interest.
                        </Text>
                    </VStack>
                </Container>
            </Box>
            <Box>
                <Container>
                    <VStack spacing={{ base: "30px", lg: "40px" }}>
                        <Heading textAlign="center" as="h2" fontSize="36px">Rethinking Client Interests Daily</Heading>
                        <SimpleGrid columns={2} gap={0} sx={{
                            ".grid-cell": {
                                borderRight: 4,
                                borderBottom: 4,
                                borderColor: "#2A4365",
                                borderStyle: "solid"
                            },
                            ".grid-cell:nth-of-type(even)": {
                                borderRight: "none",
                                // borderBottom: "none"
                            },
                            ".grid-cell:nth-of-type(n + 3)": {
                                borderBottom: "none"
                            }
                        }}>
                            {clientInterestsText.map((text, index) => (
                                <Heading
                                    key={index}
                                    className="grid-cell"
                                    as='p'
                                    textAlign="center"
                                    fontSize={{ base: 16, sm: 18, lg: 24 }}
                                    w={{ base: 160, sm: 190, lg: 240 }}
                                    p="10px">{text}</Heading>
                            ))}
                        </SimpleGrid>
                    </VStack>
                </Container>
            </Box>
            <Box>
                <Container>
                    <VStack spacing={{ base: "30px", lg: "40px" }}>
                        <Heading textAlign="center" as="h2" fontSize="36px">Representative Clients</Heading>
                        <Divider w="100px" />
                        <SimpleGrid w="full" columns={{ base: 1, lg: 3 }} spacingY={50}>
                            {representativeClientsText.map((text, index) => <BulletText key={index} showBullet={true}>{text}</BulletText>)}
                        </SimpleGrid>
                    </VStack>
                </Container>
            </Box>
            <Box>
                <Container>
                    <VStack spacing={{ base: "30px", lg: "40px" }}>
                        <Heading textAlign="center" as="h2" fontSize="36px">Expertise, Initiative & <br />Imagination</Heading>
                        <Divider w="100px" />
                        <SimpleGrid w="full" columns={{ base: 1, lg: 3 }} spacingY={50}>
                            {expertiseText.map((text, index) => <BulletText key={index} showBullet={true}>{text}</BulletText>)}
                        </SimpleGrid>
                    </VStack>
                </Container>
            </Box>
            <Box>
                <Container>
                    <VStack spacing={{ base: "30px", lg: "40px" }}>
                        <Heading textAlign="center" as="h2" fontSize="36px">Experience & Education</Heading>
                        <Divider w="100px" />
                        {
                            isMobile ?
                                <VStack spacing="20px">
                                    {experienceDropdownText.map((item, index) => <Dropdown key={index} title={item.title} content={item.content} />)}
                                </VStack> :
                                <SimpleGrid columns={2} spacingX="15px" spacingY="75px">
                                    {experienceDropdownText.map((item, index) => (
                                        <VStack key={index} spacing="25px">
                                            <Heading as="h3" fontSize="24px" textAlign="center">{item.title}</Heading>
                                            {item.content.map((c, i) => (
                                                <Box key={i}>
                                                    {c.map((s, j) => <Text key={j} textAlign="center">{s}</Text>)}
                                                </Box>
                                            ))}
                                        </VStack>
                                    ))}
                                </SimpleGrid>
                        }
                    </VStack>
                </Container>
            </Box>
            <Box>
                <Container>
                    <ContactInformation />
                </Container>
            </Box>
        </>
    )
}

export default About