import { extendTheme } from "@chakra-ui/react";
import colors from "./colors";
import components from "./components";

export const theme = extendTheme({
  colors,
  components,
  styles: {
    global: {
      html: {
        fontSize: "20px"
      },
    }
  },
  fonts: {
    heading: `'QTGraveure', serif`,
    body: `'Roboto', sans-serif`,
  },
})
