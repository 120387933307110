import { Text } from "@chakra-ui/react";
import { Link } from "react-router-dom";


const Logo = () => {
    return (
        <Link to="/">
            <Text
                as="span"
                fontFamily={`'QTGraveure', 'serif'`}
                fontWeight='bold'
                fontSize='24px'>M.S. MacPhee </Text>
            <Text
                as="span"
                fontFamily={`'QTGraveure', 'serif'`}
                fontWeight="bold"
                fontSize="17px">LLC</Text>
        </Link>
    )
}

export default Logo;