import * as React from "react"
import { Routes, Route, Outlet } from "react-router-dom";

import {
  Box,
  ChakraProvider,
  Flex,
} from "@chakra-ui/react"

import Navbar from "./components/navbar"
import Footer from "./components/footer"
import Home from "./pages/home";
import About from "./pages/about";
import Contact from "./pages/contact";
import PrivacyPolicy from "./pages/privacy-policy";
import TermsAndConditions from "./pages/terms-and-conditions";
import { NotFound } from "./pages/not-found";
import { theme } from "./theme";
import './styles/index.css'

export const App = () => (
  <ChakraProvider theme={theme}>
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route index element={<Home />} />
        <Route path="about" element={<About />} />
        <Route path="contact" element={<Contact />} />
        <Route path="privacy-policy" element={<PrivacyPolicy />} />
        <Route path="terms-and-conditions" element={<TermsAndConditions />} />
        <Route path="*" element={<NotFound />} />
      </Route>
    </Routes>
  </ChakraProvider>
);

const Layout = () => {
  return (
    <Flex direction='column' minH='100vh' sx={{
      '#outlet > div:nth-of-type(even)': {
        backgroundColor: '#F8F8F8'
      }
    }}>
      <Navbar />
      <Box id='outlet'>
        <Outlet />
      </Box>
      <Footer />
    </Flex>
  );
};