import { Box, Text } from "@chakra-ui/react"
import { Link } from "react-router-dom";

interface NavLinkProps {
    isActive?: boolean;
    to: string;
    fontSize?: string;
    children?: React.ReactNode;
}

const NavLink = ({
    isActive,
    to,
    fontSize = '24px',
    children
}: NavLinkProps) => {
    // const {
    //     isActive,
    //     to,
    //     fontSize,
    //     children
    // } = props;

    return (
        <Link to={to}>
            <Box
                as='button'
                borderBottom={isActive ? '1px' : 'none'}
                marginBottom={isActive ? '0px' : '1px'}
                _hover={{
                    cursor: 'pointer',
                    borderBottom: '1px',
                    marginBottom: '0px'
                }}
            >
                <Text
                    fontSize={fontSize}
                    style={{
                        fontVariant: 'small-caps',
                    }}>{children}
                </Text>
            </Box>
        </Link>
    )
};



export default NavLink;