import { Box, Flex, HStack, Icon, Slide, useDisclosure, VStack } from "@chakra-ui/react"
import { NavItem } from "."
// import { MdClose, MdMenu } from "react-icons/md"
import { BiX, BiMenu } from "react-icons/bi"
import { Link } from "react-router-dom"

interface MobileNavbarProps {
    navItems: NavItem[]
}

const MobileNavbar = (props: MobileNavbarProps) => {
    const {
        navItems
    } = props

    const { isOpen, onToggle } = useDisclosure()

    return (
        <Box>
            <HStack
                h='78px'
                justifyContent='flex-end'>
                <Box
                    as='button'
                    onClick={onToggle}>
                    <Icon
                        as={BiMenu}
                        boxSize='45px'
                        color='white' />
                </Box>
            </HStack>
            <Slide
                style={{
                    height: '100vh',
                    backgroundColor: '#2A4365',
                    padding: '0 15px'

                }} direction='top' in={isOpen}>
                <Flex direction='column' h='calc(100% - 78px)'>
                    <HStack
                        h='78px'
                        justifyContent='flex-end'>
                        <Box
                            as='button'
                            onClick={onToggle}>
                            <Icon
                                as={BiX}
                                boxSize='45px'
                                color='white' />
                        </Box>
                    </HStack>
                    <VStack
                        flexGrow='1'
                        justifyContent='center'
                        bg='#2A4365'
                        spacing={8}
                    >
                        {navItems.map(item => (
                            <Link key={item.label} onClick={onToggle} to={item.route}>
                                <Box
                                    fontFamily={`'QTGraveure', 'serif'`}
                                    fontWeight='bold'
                                    fontSize='40px'
                                >
                                    {item.label}
                                </Box>
                            </Link>
                        )
                        )}
                    </VStack>
                </Flex>
            </Slide>
        </Box>
    )
}

export default MobileNavbar