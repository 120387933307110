import Button from "./Button";
import Container from "./Container";
import Divider from "./Divider";
import Heading from "./Heading";

const Components = {
    Button,
    Container,
    Divider,
    Heading,
};

export default Components;