import { Box, Collapse, Divider, HStack, Icon, Text, useDisclosure, VStack } from "@chakra-ui/react"
import { BiPlus, BiMinus } from "react-icons/bi";

interface DropdownProps {
    title: string;
    content: string[][]
}

const Dropdown = (props: DropdownProps) => {
    const {
        title,
        content
    } = props;

    const {
        isOpen, onToggle
    } = useDisclosure();

    return (
        <Box w="full">
            <HStack style={{ cursor: "pointer" }} color="#2A4365" onClick={onToggle} justifyContent="space-between">
                <Text fontWeight="bold">{title}</Text>
                {content.length > 0 && <Icon as={isOpen ? BiMinus : BiPlus} boxSize="30px" />}
            </HStack>

            {
                content.length > 0 && <Collapse in={isOpen}>
                    <VStack spacing="8px" alignItems="start">
                        {content.map((c, i) => <Box key={i}>{c.map((s, j) => <Text key={j}>{s}</Text>)}</Box>)}
                        <Divider />
                    </VStack>
                </Collapse>
            }
        </Box >
    )
};

export default Dropdown;