import { Box, useBreakpointValue } from "@chakra-ui/react"
import DesktopNavbar from "./DesktopNavbar";
import MobileNavbar from "./MobileNavbar";

const navItems = [
    {
        label: 'Home',
        route: '/'
    },
    {
        label: 'About',
        route: '/about'
    },
    {
        label: 'Contact',
        route: '/contact'
    }
]

export interface NavItem {
    label: string;
    route: string;
}

const Navbar = () => {
    const isMobile = useBreakpointValue({ base: true, md: false })

    return (
        <Box
            zIndex="sticky"
            position='sticky'
            top='0'
            px='15px'
            w="full"
            bg='#2A4365'
            color='white'
            boxShadow='xl'
        >
            {isMobile ? <MobileNavbar navItems={navItems} /> : <DesktopNavbar navItems={navItems} />}
        </Box>)
}

export default Navbar