import { Box, Container, Divider, Heading, Stack, Text, useBreakpointValue, VStack } from "@chakra-ui/react"
import BulletText from "../../components/BulletText"
import ContactInformation from "../../components/ContactInformation"

const youAreHereText = [
    'Already familiar with how I can be of service.',
    'Following up on a referral from someone acquainted with my practice and avocations.',
    'Looking for a particular expertise, or to learn more about a hobbyhorse that we share.',
    'Just curious, following intuition, researching options, looking for inspiration.',
]
const allAreWelcomeText = [
    'Old friend',
    'New friend',
    'Deliberate reach-out',
    'Serendipitous opportunity',
]

const Home = () => {
    const isMobile = useBreakpointValue({ base: true, lg: false })

    return (
        <>
            <Box>
                <Container color='#2A4365' h={{ base: 'calc(100vh - 78px)', lg: 'calc(100vh - 120px)' }}>
                    <Stack h={{ base: 'calc(100% - 78px)', lg: 'calc(100% - 120px)' }} alignItems='center' direction={{ base: 'column', lg: 'row' }} justifyContent={{ base: 'center', lg: 'space-between' }}>
                        <Box>
                            <Heading
                                as='h1'
                                fontFamily={`'QTGraveure', 'serif'`}
                                fontWeight='bold'
                                fontSize={{ base: '32px', lg: '64px' }}>M.S. MacPhee <Text
                                    as='span'
                                    fontSize={{ base: '22px', lg: '40px' }}>LLC</Text>
                            </Heading>
                        </Box>
                        {!isMobile && <Divider h='200px' orientation='vertical' />}
                        <VStack spacing={{ lg: '50px' }}>
                            {[
                                'Trusted Advisory',
                                'Expertise Referrals'
                            ].map(
                                item => (
                                    <Text
                                        key={item}
                                        fontSize='24px'
                                        style={{
                                            fontVariant: 'small-caps',
                                        }}>
                                        {item}
                                    </Text>
                                )
                            )}
                        </VStack>
                    </Stack>
                </Container>
            </Box>
            <Box>
                <Container maxW="800px">
                    <Stack
                        direction={{ base: "column", lg: "row" }}
                        alignItems="center"
                        justifyContent="center"
                        spacing={{ base: "30px", lg: "60px" }}>
                        <Heading as="h2" fontSize="36px" textAlign="center">
                            You are <br /> here <br />because <br />you are
                        </Heading>
                        <Divider
                            h={{ lg: "450px" }}
                            w={{ base: "100px", lg: "2px" }}
                            orientation={isMobile ? "horizontal" : "vertical"} />
                        <VStack w="full" alignItems="start" spacing="40px">
                            {youAreHereText.map((text, index) => <BulletText key={index} showBullet={isMobile || false}>{text}</BulletText>)}
                        </VStack>
                    </Stack>
                </Container>
            </Box>
            <Box>
                <Container maxW="800px">
                    <Stack
                        direction={{ base: "column", lg: "row-reverse" }}
                        alignItems="center"
                        justifyContent="center"
                        spacing={{ base: "30px", lg: "60px" }}>
                        <Heading as="h2" fontSize="36px" textAlign="center">
                            All are <br /> welcome
                        </Heading>
                        <Divider
                            h={{ lg: "450px" }}
                            w={{ base: "100px", lg: "2px" }}
                            orientation={isMobile ? "horizontal" : "vertical"} />
                        <VStack w="full" alignItems={{ lg: "end" }} spacing="40px">
                            {allAreWelcomeText.map((text, index) => <BulletText key={index} showBullet={isMobile || false}>{text}</BulletText>)}
                        </VStack>
                    </Stack>
                </Container>
            </Box>
            <Box>
                <Container>
                    <ContactInformation />
                </Container>
            </Box>
        </>
    )
}

export default Home