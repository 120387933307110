const colors = {
    brand: {
        50: '#e9f1ff',
        100: '#c7d6ec',
        200: '#a5bbda',
        300: '#82a0ca',
        400: '#6085b9',
        500: '#466ca0',
        600: '#35547d',
        700: '#253c5a',
        800: '#142438',
        900: '#010d18',
    }
}

export default colors;