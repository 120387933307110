import { Divider, Heading, Link, Text, VStack } from "@chakra-ui/react";

const ContactInformation = () => {
    return (
        <VStack color="#2A4365" spacing={{ base: "30px", lg: "40px" }}>
            <Heading as="h1" fontSize="36px">Contact</Heading>
            <Divider w="100px" />
            <VStack spacing="20px">
                <Heading as="h2" fontSize="20px">Michael MacPhee</Heading>
                <Text>New York</Text>
                <Link href="mailto:msm@msmacphee.com">msm@msmacphee.com</Link>
                <Link href="tel:+1212-642-5445">212.642.5445</Link>
            </VStack>
        </VStack>
    )
};

export default ContactInformation;