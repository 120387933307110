import { defineStyle, defineStyleConfig } from "@chakra-ui/styled-system";

const baseStyle = defineStyle({
    borderWidth: "2px",
    borderColor: "#2A4365",
    opacity: 1
});

export default defineStyleConfig({
    baseStyle
});